<template>
    <el-main>
        <div style="margin-bottom:10px;">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd">新增分类</el-button>
        </div>

        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="商家分类">
                <el-input size="small" type="text" v-model="name" placeholder="请输入搜索分类"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id" default-expand-all
            :tree-props="{ children: '_child' }" :key="date">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="分类名称" align="center"></el-table-column>
            <el-table-column prop="store_name" label="分类等级" align="center">
                <template v-slot="{ row }">
                    {{ row.parent_id ? '二级' : '一级' }}
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="组内权重" align="center">
                <template #header>
                    <div class="edit">
                        <span>组内权重</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                        @change="editInfo(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center" fixed="right">
                <template v-slot="{ row }">
                    <el-button v-if="!row.parent_id" @click="handleAddChild(row)" type="text" size="small">添加子分类</el-button>
                    <el-button @click="handleEdit(row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleDel(row.id)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="form.id ? '编辑分类' : '添加分类'" :visible.sync="show" width="600px">
            <el-form label-width="140px" :model="form" :rules="rules" ref="form">
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="上级分类：">
                    <el-select v-model="form.parent_id" style="width: 100%;" clearable>
                        <el-option v-for=" item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <div class="tips">不选择则默认为一级分类</div>
                </el-form-item>
                <el-form-item label="组内权重：">
                    <el-input v-model="form.sort"></el-input>
                    <div class="tips">数字越大，排名越靠前;如为空，排序默认为添加时间</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
export default {
    data () {
        return {
            name: '',
            list: [],
            show: false,
            form: {
                parent_id: '',
                name: '',
                sort: ''
            },
            rules: {
                name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
            },
            date: new Date()
        };
    },
    created () {
        this.getList();
    },
    methods: {
        handleAdd () {
            this.form = {
                parent_id: '',
                name: '',
                sort: ''
            }
            this.show = true
        },
        clearSearch () {
            this.name = "";
            this.getList();
        },
        getList () {
            this.$axios.post(this.$api.repair.store.classifyList, { name: this.name }).then(res => {
                if (res.code == 0) {
                    this.list = res.result;
                    this.date = new Date()
                }
            });
        },
        handleAddChild (item) {
            this.form = {
                parent_id: item.id,
                name: '',
                sort: ''
            }
            this.show = true
        },
        handleEdit (item) {
            this.form = {
                id: item.id,
                parent_id: item.parent_id || '',
                name: item.name,
                sort: item.sort
            }
            this.show = true
        },
        confirm () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = this.form.id ? this.$api.repair.store.classifyEdit : this.$api.repair.store.classifyAdd
                    let data = { ...this.form }
                    data.parent_id = data.parent_id || 0
                    data.sort = data.sort || 99
                    this.$axios.post(url, data).then(res => {
                        if (res.code == 0) {
                            this.$message.success(this.form.id ? '编辑成功' : '添加成功');
                            this.show = false
                            this.getList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        handleDel (id) {
            this.$confirm('确认删除此条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.store.classifyDel, {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        editInfo (item) {
            this.$axios.post(this.$api.repair.store.classifyEdit, {
                id: item.id,
                parent_id: item.parent_id,
                name: item.name,
                sort: item.sort
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.getList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}

.el-main {
    background: #fff;
    display: flex;
    flex-direction: column;
}

.tips {
    color: #999;
}
</style>
